<template>
  <b-row class="match-height">
    <b-col lg="6"> <b-card
    title="Void Invoice"
  ><!-- Modal Edit-->
    <b-modal
      id="modal-invoice"
      v-model="modalShow"
      centered
      hide-footer
      title="Invoice Detail"
      modal-class="modal-danger"
    >
        <b-form-group>
          <label for="email">Invoice No:</label>
          <input type="text" name="InvoiceNo" class="form-control" v-model="InvoiceNumber" readonly>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Job No</label>
          <input type="text" name="JobNo" class="form-control" v-model="JobNo" readonly>
        </b-form-group>
        <b-form-group>
          <label for="email">Invoice Date:</label>
          <input type="text" name="InvoiceDate" class="form-control" v-model="InvoiceDate" readonly>
        <b-form-datepicker
          id="invoice-date"
          v-model="InvoiceDate"
        />
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Customer Name</label>
          <input type="text" name="CustomerName" class="form-control" v-model="CustomerName" readonly>
        </b-form-group>
        <div class="pull-right">
          <b-btn @click="modalShow = false" class="btn btn-outline-secondary">Cancel</b-btn> &nbsp;
          <b-btn variant="outline-primary"
                type="button" @click="voidInvoiceProcess();" class="btn btn-danger">Delete</b-btn>
        </div>
    </b-modal>
    <b-form
        @submit.prevent="voidCheck">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Invoice Number *"
            label-for="invoice_number"
            label-cols-md="4"
          >
            <b-form-input
              id="invoice_number"
              placeholder="Invoice Number"
              required="required"
              v-model="InvoiceNumber"
            />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>

    </b-form>

  </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BModal, BButton, VBModal, BCard,
  } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import moment from 'moment'
import { getToken } from '@/auth/utils'

const userToken = getToken()
const headers = {
  'Content-Type': 'application/json',
  'X-Token-Access': `Bearer ${userToken}`,
}
const modalShow = false
const InvoiceNumber = ''
const JobNo = ''
const InvoiceDate = ''
const CustomerName = ''
const InvoiceID = ''
export default {
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
    InvoiceNumber,
    modalShow,
    JobNo,
    InvoiceDate,
    CustomerName,
    InvoiceID,
    }
  },
  methods: {
  voidCheck() {
      const body = {
        InvoiceNumber: this.InvoiceNumber,
      }
    axios
      .post('https://api.geolims.com/invoice_service/getdetailpusat', body, { headers })
      .then(response => {
        if (response.data.Status === 1 && response.data.Payload.length > 0) {
          this.modalShow = true
          this.JobNo = response.data.Payload[0].JobNo
          this.InvoiceDate = this.dateFormat(response.data.Payload[0].InvoiceDate)
          this.CustomerName = response.data.Payload[0].Customer
          this.InvoiceID = response.data.Payload[0].InvoiceID
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice Valid',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invoice Number Invalid!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invoice Number Invalid!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  voidInvoiceProcess() {
      const body = {
        InvoiceID: this.InvoiceID,
      }
    axios
      .post('https://api.geolims.com/invoice_service/voidinvoice', body, { headers })
      .then(response => {
        if (response.data.Status === 1) {
          this.modalShow = false
          this.InvoiceNumber = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Void Invoice Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Void Invoice Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Void Invoice Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
    dateFormat(value, format = 'MM/DD/YYYY') {
        return moment(String(value)).format(format)
    },
  },
}
</script>
